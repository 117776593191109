const req = require.context('@/assets', true, /\.jpg|.png$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)

const images = requireAll(req);

function init(callBack){
	var currentNums = 0;
	var totalProgress = 0;
	var progress =0;
	images.forEach(item=>{
		let image = new Image();
		image.onload = addProgress
		image.onerror = addProgress
		image.src = item 
	})
	function addProgress(){
		currentNums++;
		totalProgress = currentNums/images.length*100;
	}
	function step(){
		if(totalProgress>progress){
			progress++;
			callBack(progress)
		}
		if(progress<100){
			window.requestAnimationFrame(step);
		}
	}
	step()
}

export default init