<template>
  <div>
    <img class="page-bg" :src="config?.home" />
    <div class="page-container">
      <div class="title-box" style="opacity:0;">
        <img class="title-icon" src="@/assets/title.png" />
      </div>
      <div class="plan-box">
        <div class="plan-icon" :style="{width:`${planNum}%`}"></div>
      </div>
      <img class="text-icon" src="@/assets/loading/text.png" />
    </div>
    <Logo></Logo>
  </div>
</template>

<script type="text/javascript">
import init from '@/utils/loadImg'
import Logo from "@/components/Logo.vue"

//api
import { config } from "@/api";

//store
import {mapState} from 'vuex'

//util
import { getUrlParams } from '@/utils/util';

export default {
  name: "Loading",
  data() {
    return {
      planNum:0,//加载进度
    }
  },
  computed:{
    ...mapState(['config','userInfo']),
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  components: {Logo},
  created(){
    this.getLoginStatus()
  },
  mounted(){
    
  },
  methods:{
    getLoginStatus(){//获取登录状态
      //测试专用
      // let params = {token:"suiToken",uid:"suiUid",nickname:"天桥Xiao耳朵"}
      // const { uid,token,nickname } = params
      //正式服
      const { uid,token,nickname } = getUrlParams()
      if(uid&&token&&nickname){
        this.$store.commit('SET_USERINFO', {uid,token:decodeURI(token),nickname:decodeURI(nickname)})
      }
      this.loadingPlan()
    },
    async loadingPlan(){//页面加载进度
      let result = await this.getConfig()
      init(progress=>{
        this.planNum = progress
        if(progress==100&&result){
          this.$store.commit("SET_LOADINGSTATE",true)
          this.$router.replace({ path: (this.redirect&&this.userInfo)?this.redirect:'/' })
        }
      })
    },
    getConfig(){//获取活动图片
      return new Promise((success,fail)=>{
        config().then(res=>{
          let obj = {}
          res.forEach(item=>obj[item.location] = item.url)
          this.$store.commit("SET_CONFIG",obj)
          success(true)
        })
      })
    },
  }
}
</script>

<style scoped>
  .page-container,.page-bg{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 7.5rem;
    height: 15rem;
    object-fit: cover;
  }
  .title-box{
    padding-top: 1.8rem;
    width: 5.02rem;
    height: 1.83rem;
    margin: 0 auto;
  }
  .title-icon{
    width: 100%;
    height: 100%;
  }
  .plan-box{
    width: 4.95rem;
    height: 0.34rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 0.3rem 0 rgba(105,158,200,0.31);
    border-radius: 0.17rem;
    padding: 0.02rem 0.03rem;
    box-sizing: border-box;
    margin: 7.93rem auto 0.17rem;
  }
  .plan-icon{
    width: 0;
    height: 100%;
    background: linear-gradient(93deg, #B1DFFF 0%, #D2B3F4 100%);
    border-radius: 0.15rem;
  }
  .text-icon{
    margin: 0 auto;
    width: 1.69rem;
    height: 0.47rem;
  }
</style>
